import { ApolloProvider } from '@apollo/client'
import withApollo from 'next-with-apollo'
import SplashScreen from '../components/SplashScreen'
import createApolloClient from '../utils/createApolloClient'
import { AuthProvider } from '../utils/auth'
import { PlayerProvider } from '../utils/song_player'
import * as ga from '../lib/analytics.js'
// Reusable styles for the entire app
import './../sass/app.scss'
import './../sass/fonts.css'
import { ToastProvider } from '../components/complex/Toast'
import { createContext, useEffect, useState } from 'react'
import { LiveChatLoaderProvider } from 'react-live-chat-loader'
import { ModalsProvider } from '../utils/modals'
import App from 'next/app'
import { useRouter } from 'next/router'
import Scripts from '../components/Scripts'
import Intercom from '../components/Intercom'
import { ThemeProvider } from 'next-themes'
import { SpeedInsights } from '@vercel/speed-insights/next'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export const BlockScrollContext = createContext(null)

const WorshipOnline = ({ Component, pageProps, apollo }) => {
  const [blockScroll, setBlockScroll] = useState(false)
  const isProd = process.env.NODE_ENV === 'production'
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (isProd) {
        ga.pageview(url)
      }
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return (
    <>
      <Scripts />
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      >
        <BlockScrollContext.Provider
          value={{
            setBlockScroll
          }}
        >
          <div className={`${blockScroll ? 'blockScroll' : ''}`}>
            <LiveChatLoaderProvider
              providerKey={process.env.NEXT_PUBLIC_INTERCOM_APP_ID}
              provider="intercom"
            >
              <ToastProvider>
                <Intercom color="#a822b4" />
                <ApolloProvider client={apollo}>
                  <AuthProvider>
                    <ThemeProvider
                      enableSystem={false}
                      enableColorScheme={false}
                      defaultTheme="dark"
                      forcedTheme={Component.theme || null}
                    >
                      <SplashScreen />
                      <PlayerProvider>
                        <ModalsProvider>
                          <Component {...pageProps} />
                          <SpeedInsights />
                        </ModalsProvider>
                      </PlayerProvider>
                    </ThemeProvider>
                  </AuthProvider>
                </ApolloProvider>
              </ToastProvider>
            </LiveChatLoaderProvider>
          </div>
        </BlockScrollContext.Provider>
      </GoogleReCaptchaProvider>
    </>
  )
}

WorshipOnline.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext)
  return { ...appProps }
}

export default withApollo(({ initialState, headers }) => {
  return createApolloClient(initialState, headers?.cookie)
})(WorshipOnline, {})
